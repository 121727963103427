<template>
  <div :class="sidebarClasses">
    <div
      id="sidebar-nav-fluid"
      class="sidebar-nav-fluid"
    >
      <div class="sidebar-nav__top">
        <b-button
          v-b-toggle.sidebar-nav-fluid
          @click="routeTo('/')"
          variant="link"
          class="sidebar-nav__collapser d-md-none d-lg-block"
        >
          <img
            :src="logoBadge"
            alt="MF Solvency"
          >
        </b-button>
        <b-button
          @click="changeNavState"
          variant="link"
          class="sidebar-nav__collapser d-none d-md-block d-lg-none"
        >
          <img
            :src="logoBadge"
            alt="MF Solvency"
          >
        </b-button>
      </div>
      <nav-items vertical classes="sidebar-nav" />
    </div>
  </div>
</template>

<script>

const NavItems = () => import('./NavItems.vue');

export default {
  name: 'Sidebar',
  components: { NavItems },
  data() {
    return {
      // eslint-disable-next-line global-require
      logoBadge: require('@/assets/img/logo-hor-MFSOLVENCY.png'),
      sidebarIsOpened: false,
    };
  },
  props: {},
  computed: {
    sidebarClasses() {
      const a = 'sidebar-main sidebar-fluid';
      if (this.sidebarIsOpened) {
        return `${a} sidebar--is-closed`;
      }
      return a;
    },
  },
  methods: {
    routeTo(path) {
      this.$router.push(path);
    },
    changeNavState() {
      // console.log('change navClasses');
      this.sidebarIsOpened = !this.sidebarIsOpened;
    },
  },
};
</script>

<style scoped>

</style>
